import { convertDateFormat } from "../utils/date";

const axios = require("axios");

const APP_API_URL = process.env.REACT_APP_RESTAPI_URL;
const APP_CLIENT = process.env.REACT_APP_CLIENT;
const APP_CONNECT_INSTANCE = process.env.REACT_APP_CONNECT_INSTANCE;
const CF_APP_API_URL = process.env.REACT_APP_RESTAPI_ON_PREM_URL;
const TOKEN_URL = process.env.REACT_APP_TOKEN_URL;
const POST_TIMEOUT = process.env.REACT_APP_POST_REQUEST_TIMEOUT;
axios.defaults.timeout = POST_TIMEOUT;

function postRequest(action, payload, resource) {
  return new Promise((resolve, reject) => {
    try {
      const headers = { "Content-Type": "application/json" };

      const body = Object.assign({}, payload, {
        clientName: APP_CLIENT,
        resource,
        headers,
      });

      const response = axios.post(`${APP_API_URL}/${action}`, body);
      return resolve(response);
    } catch (e) {
      return reject(new Error(e));
    }
  });
}

function onPremPostRequest(action, payload) {
  return new Promise((resolve, reject) => {
    try {
      const headers = { "Content-Type": "application/json" };

      const body = Object.assign({}, payload, {
        headers,
      });

      const response = axios.post(`${CF_APP_API_URL}/${action}`, body);
      return resolve(response);
    } catch (e) {
      return reject(new Error(e));
    }
  });
}

function getRequest(action) {
  return new Promise((resolve, reject) => {
    try {
      let url = `${APP_API_URL}/${action}`;

      const response = axios.get(url, {
        headers: { clientname: APP_CLIENT },
      });
      return resolve(response);
    } catch (e) {
      console.log(`[getRequest - ${action}]: `, e);
      return reject(new Error(e));
    }
  });
}

async function checkForAvailableAgent() {
  return new Promise(async (resolve, reject) => {
    try {
      const payload = {
        action: "checkForAvailableAgent",
        data: {
          InstanceName: APP_CONNECT_INSTANCE,
          Metrics: ["AGENTS_AVAILABLE"],
        },
      };

      const response = await postRequest(
        "checkForAvailableAgent",
        payload,
        "checkForAvailableAgent"
      );

      return resolve(response.data);
    } catch (e) {
      return reject(e);
    }
  });
}
async function getCustomerReservationByMeetingID(meetingId) {
  return new Promise(async (resolve, reject) => {
    try {
      if (!meetingId) return reject(new Error("missing meeting Id"));

      const payload = {
        action: "getReservationDetails",
        meetingId,
      };

      const response = await postRequest(
        "getcustomerreservation",
        payload,
        "customerReservation"
      );
      return resolve(response.data);
    } catch (e) {
      return reject(e);
    }
  });
}

async function createCustomerReservation(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      if (!payload) return reject(new Error("missing payload"));
      if (!payload.first_name) return reject(new Error("missing first name"));
      if (!payload.last_name) return reject(new Error("missing last name"));
      if (!payload.number) return reject(new Error("missing mobile number"));
      if (!payload.phoneType) return reject(new Error("missing phone type"));
      if (!payload.meetingID) return reject(new Error("missing meeting id"));

      const response = await postRequest(
        "reservation/customer",
        payload,
        "customerReservation"
      );
      return resolve(response.data);
    } catch (e) {
      return reject(e);
    }
  });
}

async function createCustomerReservationOnPrem(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await onPremPostRequest(
        "reservation/register-reservation",
        payload
      );
      return resolve(response.data);
    } catch (e) {
      return reject({ onPremErr: true, ...e });
    }
  });
}

async function getAvailabilityOfAgents() {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await getRequest("getAvailabilityOfAgents");
      return resolve(response.data);
    } catch (e) {
      return reject(e);
    }
  });
}

async function sendSMSConfirmation(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      if (!payload) return reject(new Error("missing payload"));
      if (!payload.phoneNumber)
        return reject(new Error("missing phone number"));
      if (!payload.message) return reject(new Error("missing message"));

      const response = await postRequest("send", payload);
      return resolve(response.data);
    } catch (e) {
      return reject(e);
    }
  });
}

async function getNumberOfUnassignedReservation() {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await getRequest("getcustomerreservation");
      return resolve(response.data);
    } catch (e) {
      return reject(e);
    }
  });
}

async function checkWithinOfficeHours(currentTime) {
  return new Promise(async (resolve, reject) => {
    try {
      const payload = {
        action: "checkOfficeHours",
      };
      const response = await postRequest("checkTime", payload, "checkTime");
      return resolve(response.data);
    } catch (e) {
      return reject(e);
    }
  });
}

function getToken() {
  return new Promise((resolve, reject) => {
    try {
      let url = `${TOKEN_URL}`;
      const response = axios.get(url);
      return resolve(response);
    } catch (e) {
      console.log(`[getRequest: `, e);
      return reject(new Error(e));
    }
  });
}

async function sendNotif(data) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await postRequest("reservation/sendnotif", data);
      return resolve(response.data);
    } catch (e) {
      return reject(e);
    }
  });
}

export {
  getCustomerReservationByMeetingID,
  createCustomerReservation,
  sendSMSConfirmation,
  checkForAvailableAgent,
  getAvailabilityOfAgents,
  getNumberOfUnassignedReservation,
  createCustomerReservationOnPrem,
  checkWithinOfficeHours,
  getToken,
  sendNotif,
};
